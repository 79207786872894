/**
 * Entry point of the child-theme
 * php version 7.4.3
 *
 * @category Theme
 * @package  KadabraitHelloChildTheme
 */

(function ($) { // phpcs:ignore
	'use strict';
	$(document).ready(
		function () {

			$('body').on(
				'click',
				'.dropdown-filter-teams',
				function () {
					$(this)
						.addClass('dropdown-open close')
						.removeClass('dropdown-closed');
					$(this).parent().children('.dropdown_checks')
						.removeClass('d-none');
				}
			);
			$(document).click(function (e) {
				if ($(e.target).closest("#tv-filters .elementor-widget-container").length === 0) {
					$('#tv-filters .elementor-widget-container').children().each(
						function () {
							if ($(this).hasClass('dropdown-open')) {
								$(this)
									.addClass('dropdown-closed')
									.removeClass('dropdown-open close');
							}
							if ($(this).hasClass('dropdown_checks')) {
								$(this)
									.addClass('d-none');
							}
						}
					);
				}
			});
			$('body').on(
				'click',
				'#tv-filters .close',
				function () {
					$(this).parent().parent().parent().children().each(
						function () {
							if ($(this).hasClass('dropdown-open')) {
								$(this)
									.addClass('dropdown-closed')
									.removeClass('dropdown-open close');
							}
							if ($(this).hasClass('dropdown_checks')) {
								$(this)
									.addClass('d-none');
							}
						}
					);
				}
			);
			$(document).on('keydown', function (event) {
				if (event.key == "Escape") {
					$('.dropdown-list-teams')
						.addClass('d-none');
				}
			});
			$('body').on(
				'click',
				'#tv-filters .dropdown-filter-overlay-team',
				function () {
					var filter_values = [];
					var filter_location = [];
					$(this).parent().children().each(
						function () {
							if ($(this).parent().hasClass('show-all')) {
								if ($(this).hasClass('checked-true')) {
									$(this)
										.css('pointer-events', 'none');
								} else {
									$(this)
										.css('pointer-events', 'auto');
									if ($(this).hasClass('dropdown-filter-checkbox')) {
										$(this)
											.addClass('checked-true')
											.removeClass('checked-false')
											.html('<i class="fas fa-check"></i>');
									}
								}

							} else {
								if ($(this).hasClass('checked-false')) {
									$(this)
										.addClass('checked-true')
										.removeClass('checked-false')
										.html('<i class="fas fa-check"></i>');
									var data_filters = '';
									data_filters += '<div class="filter-row-item d-flex asset-based-lending"';
									data_filters += 'data-filter="' + $(this).parent().attr('data-filter') + '">';
									data_filters += '<img src="/wp-content/themes/hello-elementor-child/img/close-icon.svg">';
									data_filters += '<div class="filter-row-item-title">' + $(this).parent().children('.dropdown-filter-title').text() + '</div></div>';
									$('#filters-row-items-teams').append(data_filters);
								} else if ($(this).hasClass('checked-true')) {
									$(this)
										.addClass('checked-false')
										.removeClass('checked-true')
										.empty();
									var remove_filter = $(this).parent().attr('data-filter');
									$('#filters-row-items-teams .filter-row-item').each(
										function () {
											if ($(this).attr('data-filter') === remove_filter) {
												$(this).remove();
											}
										}
									);
								}

							}

						}
					);
					$('#filter-title .dropdown-list-teams .dropdown-filter-item-teams').each(
						function (e) {
							var data_filter = $(this).attr('data-filter');
							if ($(this).children().hasClass('checked-true')) {
								filter_values.push(data_filter);
							} else {
								var index = filter_values.indexOf(data_filter);
								if (index !== -1) {
									filter_values.splice(index, 1);
								}
							}
							if ($(this).hasClass('show-all')) {
								filter_values = [];
							}
						}
					);

					$('#filter-location .dropdown-list-teams  .dropdown-filter-item-teams').each(
						function (e) {
							var data_filter = $(this).attr('data-filter');
							if ($(this).children().hasClass('checked-true')) {
								filter_location.push(data_filter);
							} else {
								var index = filter_location.indexOf(data_filter);
								if (index !== -1) {
									filter_location.splice(index, 1);
								}
							}
							if ($(this).hasClass('show-all')) {
								filter_location = [];
							}
						}
					);
					$('.tv-accordion-group').removeClass('active');
					filter_people(filter_values, filter_location);
					close_filters_all();
				}
			);

			$('body').on(
				'click',
				'.show-all',
				function () {
					var filter_values = [];
					var filter_location = [];
					$(this).parent().find('.show-current .dropdown-filter-checkbox').each(
						function () {
							$(this)
								.removeClass('checked-true')
								.addClass('checked-false')
								.empty();
						}
					);
					if ($(this).parent().parent().hasClass('p-job-title')) {

						$(this).parent().children().each(
							function () {
								var remove_filter = $(this).attr('data-filter');
								$('#filters-row-items-teams .filter-row-item').each(
									function () {
										if ($(this).attr('data-filter') === remove_filter) {
											$(this).remove();
										}
									}
								);
							}
						);

					}
					if ($(this).parent().parent().hasClass('p-location')) {

						$(this).parent().children().each(
							function () {
								var remove_filter = $(this).attr('data-filter');
								$('#filters-row-items-teams .filter-row-item').each(
									function () {
										if ($(this).attr('data-filter') === remove_filter) {
											$(this).remove();
										}
									}
								);
							}
						);
					}
					$('#filter-title .dropdown-list-teams .dropdown-filter-item-teams').each(
						function (e) {
							var data_filter = $(this).attr('data-filter');
							if ($(this).children().hasClass('checked-true')) {
								filter_values.push(data_filter);
							} else {
								var index = filter_values.indexOf(data_filter);
								if (index !== -1) {
									filter_values.splice(index, 1);
								}
							}
							if ($(this).hasClass('show-all')) {
								filter_values = [];
							}
						}
					);

					$('#filter-location .dropdown-list-teams  .dropdown-filter-item-teams').each(
						function (e) {
							var data_filter = $(this).attr('data-filter');
							if ($(this).children().hasClass('checked-true')) {
								filter_location.push(data_filter);
							} else {
								var index = filter_location.indexOf(data_filter);
								if (index !== -1) {
									filter_location.splice(index, 1);
								}
							}
							if ($(this).hasClass('show-all')) {
								filter_location = [];
							}
						}
					);
					$('.tv-accordion-group').removeClass('active');
					filter_people(filter_values, filter_location);
					close_filters_all();
				}
			);

			$('body').on(
				'click',
				'.show-current',
				function () {
					var checked = [];
					var parent_name = $(this).parent().data('name');

					// Uncheck the show all filter.
					$(this)
						.parent()
						.find('.show-all .dropdown-filter-checkbox')
						.removeClass('checked-true')
						.addClass('checked-false')
						.empty();

					// Push the check status into the checked array.
					$(this).parent().find('.show-current .dropdown-filter-checkbox').each(
						function () {
							checked.push($(this).hasClass('checked-true'));
						}
					);

					// Check the show all filter or not, it depends.
					if (!checked.includes(true)) {
						$(this)
							.parent()
							.find('.show-all .dropdown-filter-checkbox')
							.addClass('checked-true')
							.removeClass('checked-false')
							.html('<i class="fas fa-check"></i>');
					}
				}
			);

			let _id = '';
			let _name = '';
			let _location = 'all';
			let _team = [];
			let _teams = [];
			let _location_filter = [];
			let _job_filter = [];
			let _fstatus = false;
			let _titlecat = '';
			let filter_team = [];
			let currenturl = window.location.href;
			if ($('#teams-view')) {
				$('.tv-cat-item').each(
					function (i, el) {
						if ($(el).hasClass('active')) {
							_id = $(el).data('id');
							if (_id != '9') {
								$('#teams-view #tv-search').css({ display: 'flex' });
								$('#teams-view #tv-filters').css({ display: 'flex' });
							} else {
								$('#teams-view #tv-search').css({ display: 'none' });
								$('#teams-view #tv-filters').css({ display: 'none' });
							}
							_titlecat = $(el).text().replace(' + Apollo Advisors', '');
							$('#tv-titlecat span.title').text(_titlecat);
						}
					}
				);
				if (currenturl.includes("solution-team")) {
					_id = $('#solution-team').attr('data-id');
					$('#teams-view #tv-search').css({ display: 'flex' });
					$('#teams-view #tv-filters').css({ display: 'flex' });
				} else if (currenturl.includes("corporate-infrastructure-leadership")) {
					_id = $('#corporate-infrastructure-leadership').attr('data-id');
					$('#teams-view #tv-search').css({ display: 'flex' });
					$('#teams-view #tv-filters').css({ display: 'flex' });
				}
				$('.tv-cat-item').each(
					function () {
						if (currenturl.includes($(this).attr('id'))) {
							$('.tv-cat-item').removeClass('active');
							$(this).addClass('active');
							_titlecat = $(this).text();
							$('#tv-titlecat span.title').text(_titlecat);
						}
					}
				);
				loadPeople(_id, _name, _location);

				$('.tv-cat-item').click(
					function (e) {
						_teams = [];
						_location = 'all';
						_fstatus = false;
						$('div#filters-row-teams #filters-row-items-teams').empty();
						$('div#filters-row-teams #filters-row-close').addClass('d-none').removeClass('d-block');
						$('.dropdown-list-teams .dropdown-filter-item-teams').each(
							function () {
								if ($(this).hasClass('show-all')) {
									$(this).children('.dropdown-filter-checkbox')
										.addClass('checked-true')
										.removeClass('checked-false')
										.html('<i class="fas fa-check"></i>');
								} else {
									$(this).children('.dropdown-filter-checkbox')
										.addClass('checked-false')
										.removeClass('checked-true')
										.empty();
								}
							}
						)
						$('#teams-view #tv-locations').val('all');
						if (!$(this).hasClass('active')) {
							$('#teams-view #tv-search input').val('');
							_id = $(this).data('id');
							if (_id != '9') {
								$('#teams-view #tv-search').css({ display: 'flex' });
								$('#teams-view #tv-filters').css({ display: 'flex' });
							} else {
								$('#teams-view #tv-search').css({ display: 'none' });
								$('#teams-view #tv-filters').css({ display: 'none' });
							}
							_titlecat = $(this).text();
							$('#tv-titlecat span.title').text(_titlecat);
							loadPeople(_id, _name, _location);
						}
						$('.tv-cat-item').removeClass('active');
						$(this).addClass('active');
						return false;
					}
				);
				$('#teams-view #tv-search input').keypress(
					function (event) {
						if (event.keyCode == 13) {
							_name = $(this).val();
							if (_name != '') {
								_fstatus = true;
								loadPeople(_id, _name, _location);
								_name = '';
							} else {
								_fstatus = false;
							}
						}
						if ($(this).val() == '') {
							_fstatus = false;
							loadPeople(_id, _name, _location);
						}
					}
				);
				$('#teams-view #tv-search input').keyup(
					function (event) {
						_name = $(this).val();
						if (_name != '') {
							_fstatus = true;
							loadPeople(_id, _name, _location);
						} else {
							_fstatus = false;
						}
						if ($(this).val() == '') {
							_fstatus = false;
							loadPeople(_id, _name, _location);
						}
					}
				);
				$('#teams-view #tv-search img').click(
					function (e) {
						if ($('#teams-view #tv-search input').val() != '') {
							_name = $('#teams-view #tv-search input').val();
							loadPeople(_id, _name, _location);
							_name = '';
						} else {
							loadPeople(_id, _name, _location);
						}
						return false;
					}
				);
				$('body').on(
					'click',
					'.tv-accordion .tv-accordion-group .tv-accordion-title',
					function () {
						if ($(this).parent().hasClass("active")) {
							$(this).parent().removeClass("active");
							$(this).parent().find(".tv-accordion-body-part").slideUp();
						} else {
							$(".tv-accordion").removeClass("active");
							$(".tv-accordion").find(".tv-accordion-body-part").slideUp();
							$(this).parent().addClass("active");
							$(this).parent().find(".tv-accordion-body-part").slideDown();
						}
						return false;
					}
				);

			}
			function loadPeople(id, name, location = '', team = 'all', filter = []) {
				var filter_2 = filter.toString().split(",");
				$('#tv-content').html("<div id='tv-infoload'><span>loading...</span></div>");
				$.ajax(
					{
						method: 'GET',
						dataType: 'json',
						url: '/wp-json/midcap/v2/list-peoples',
						data: { id, name, location, team: filter_2 },
						success: function (data, status, xhr) {
							if (data.items.length > 0) {
								$('#tv-content').html("");
								showPeople(data.items, data.apollo, data.teams, data.location_filter, data.job_term);
							} else {
								$('#tv-content').html("<div id='tv-infoload'><span>No data.</span></div>");
							}
						},
						error: function (jqXhr, textStatus, errorMessage) {
							$('#tv-content').html("<div id='tv-infoload'><span>No data.</span></div>");
						}
					}
				);
			}
			function showPeople(items, apollo, teams = [], location_filter = [], job_term = []) {
				if ($('#tv-content')) {

					if (_id == '9') {
						presentationPeople('grid', items, apollo);
					} else {
						presentationPeople('accordeon', items, teams, location_filter, job_term);
					}

				}
			}
			function presentationPeople(type, items, apollo, teams = [], location_filter = [], job_term = []) {
				let _html = '';
				let _teamshtml = '';
				let _locationhtml = '';
				let _jobhtml = '';

				switch (type) {
					case 'grid':
						_html = "<ul class='tv-peoples'>";
						for (let item of items) {
							_html += "<li class='tv-people-item'>";
							_html += "<a href='" + item.url + "'>";
							_html += "<div class='image-people' style='background-image: url(" + item.photo_people + ")'></div>";
							_html += "</a>";
							_html += "<h3><a href='" + item.url + "'>" + item.display_name_people + "</a></h3>";
							_html += "<h5>" + item.job_title_people + "</h5>";
							_html += "<span>" + item.functional_titles + "</span>";
							_html += "</li>";
						}
						_html += "</ul>";
						_html += "<h3><span class='title'>Apollo Management Advisors</span></h3>";
						_html += "<ul class='tv-peoples apollo'>";
						for (let item of apollo) {
							_html += "<li class='tv-people-item'>";
							_html += "<a href='" + item.url + "'>";
							_html += "<div class='image-people' style='background-image: url(" + item.photo_people + ")'></div>";
							_html += "</a>";
							_html += "<h3><a href='" + item.url + "'>" + item.display_name_people + "</a></h3>";
							_html += "<h5>" + item.job_title_people + "</h5>";
							_html += "<span>" + item.functional_titles + "</span>";
							_html += "</li>";
						}
						_html += "</ul>";
						$('#tv-content').html(_html);
						break;
					case 'accordeon':
						_html = "<div class='tv-accordion'>";
						for (let item of items) {
							let _show = (item.items.length > 0 && _fstatus == true) ? 'block' : 'none';
							let _active = (item.items.length > 0 && _fstatus == true) ? 'active' : '';
							let _item_result = (item.items.length === 0) ? 'enabled' : '';
							let _childs = "<ul class='tv-peoples'>";
							for (let child of item.items) {
								_childs += "<li class='tv-people-item' data-filter='" + child.data_filter_slug + "'";
								_childs += "data-location='" + child.data_filter_location + "'>";
								_childs += "<a href='" + child.url + "'>";
								_childs += "<div class='image-people' style='background-image: url(" + child.photo_people + ")'></div>";
								_childs += "</a>";
								_childs += "<h3><a href='" + child.url + "'>" + child.display_name_people + "</a></h3>";
								_childs += "<h5>" + child.job_title_people + "</h5>";
								_childs += "<span>" + child.functional_titles + "</span>";
								_childs += "</li>";
							}
							_childs += "</ul>";
							_html += "<div class='tv-accordion-group  " + _active + "' id='" + item.team.toLowerCase().replace(/\s/g, '-') + "'>";
							_html += "<a class='tv-accordion-title " + _item_result + "' href='#'>" + item.team + "</a>";
							_html += "<div class='tv-accordion-body-part' style='display: " + _show + "'>" + _childs + "</div>";
							_html += "</a>";
							_html += "</div>";
						}
						_html += "</div>";
						$('#tv-content').html(_html);
						break;
				}
			}

			
			/**
			 * Sort the people post type list in the admin area
			 */
			
			// Check if we are on the 'people' post type list in the admin area
			const urlParams = new URLSearchParams(window.location.search);
			const postType = urlParams.get('post_type');

			if (postType === 'people') {

				// Select all rows in the table
				const rows = $('#the-list tr');
				
				// Sort the rows based on custom data attributes
				const orderedRows = rows.sort(function (a, b) {

					// Extract `data-*` attributes for comparison
					const metaA = $(a).find('.column-custom_order_column div');
					const metaB = $(b).find('.column-custom_order_column div');

					// Parse the functional title ID, default to 9999 if not present
					const functionalTitleIdA = parseInt(metaA.data('functional-title-id')) || 9999;
					const functionalTitleIdB = parseInt(metaB.data('functional-title-id')) || 9999;

					// Parse the job title ID, default to 9999 if not present
					const jobTitleIdA = parseInt(metaA.data('job-title-id')) || 9999;
					const jobTitleIdB = parseInt(metaB.data('job-title-id')) || 9999;

					// Parse the job title order, default to 9999 if not present
					const jobTitleOrderA = parseInt(metaA.data('job-title-order')) || 9999;
					const jobTitleOrderB = parseInt(metaB.data('job-title-order')) || 9999;

					// Sort first by job title order
					if (jobTitleOrderA !== jobTitleOrderB) {
						return jobTitleOrderA - jobTitleOrderB;
					}

					// If job title order is equal, sort by functional title ID
					if (functionalTitleIdA !== functionalTitleIdB) {
						return functionalTitleIdA - functionalTitleIdB;
					}

					// If functional title ID is also equal, sort by job title ID
					return jobTitleIdA - jobTitleIdB;
				});

				// Replace the existing table rows with the newly sorted rows
				$('#the-list').html(orderedRows);

			}

		}

	);

})(jQuery); // phpcs:ignore

function filter_people(filter_values, filter_location) {
	var card_filter = [];
	var location_filter = [];
	if (filter_values.length > 0) {
		jQuery('.tv-accordion-group').removeClass('active');
		jQuery('.tv-accordion-body-part').css('display', 'none');
		jQuery('.tv-people-item').each(
			function () {
				card_filter = jQuery(this).attr('data-filter').split(' ');
				var aux_array = jQuery(card_filter).filter(filter_values).get();
				if (aux_array.length > 0) {
					jQuery(this).parent().parent().css('display', 'block');
					jQuery(this).parent().parent().parent().find('a').removeClass('enabled');
					jQuery(this).parent().parent().parent().addClass('active');
					jQuery(this).attr('style', 'display:block;');
				} else {
					jQuery(this).attr('style', 'display:none');
					jQuery(this).parent().parent().parent().find('a').addClass('enabled');
				}
			}
		);
	}
	if (filter_location.length > 0) {
		jQuery('.tv-accordion-group').removeClass('active');
		jQuery('.tv-accordion-body-part').css('display', 'none');
		jQuery('.tv-people-item').each(
			function () {
				location_filter = jQuery(this).attr('data-location').split(' ');
				var aux_array2 = jQuery(location_filter).filter(filter_location).get();
				if (aux_array2.length > 0) {
					jQuery(this).parent().parent().css('display', 'block');
					jQuery(this).parent().parent().parent().find('a').removeClass('enabled');
					jQuery(this).parent().parent().parent().addClass('active');
					jQuery(this).attr('style', 'display:block;');
				} else {
					jQuery(this).attr('style', 'display:none');
					jQuery(this).parent().parent().parent().find('a').addClass('enabled');
				}

			}
		);
	}
	if (filter_values.length > 0 && filter_location.length > 0) {
		jQuery('.tv-accordion-group').removeClass('active');
		jQuery('.tv-accordion-body-part').css('display', 'none');
		jQuery('.tv-people-item').each(
			function () {
				card_filter = jQuery(this).attr('data-filter').split(' ');
				var aux_array = jQuery(card_filter).filter(filter_values).get();
				location_filter = jQuery(this).attr('data-location').split(' ');
				var aux_array2 = jQuery(location_filter).filter(filter_location).get();
				if (aux_array.length > 0 && aux_array2.length > 0) {
					jQuery(this).parent().parent().css('display', 'block');
					jQuery(this).parent().parent().parent().addClass('active');
					jQuery(this).parent().parent().parent().find('a').removeClass('enabled');
					jQuery(this).attr('style', 'display:block;');
				} else {
					jQuery(this).attr('style', 'display:none');
					jQuery(this).parent().parent().parent().find('a').addClass('enabled');
				}

			}
		);
	}
	if (filter_values.length === 0 && filter_location.length === 0) {
		jQuery('.tv-people-item').each(
			function () {
				jQuery(this).parent().parent().css('display', 'none');
				jQuery(this).parent().parent().parent().find('a').removeClass('enabled');
				jQuery(this).parent().parent().parent().removeClass('active');
				jQuery(this).attr('style', 'display:block;');
			}
		);
	}
	jQuery('.tv-accordion-group').each(
		function () {
			if (jQuery(this).hasClass('active')) {
				jQuery(this).find('a').removeClass('enabled');
			}
		}
	);
}
function close_filters_all() {
	var list_filter = '';
	list_filter = jQuery('#filters-row-items-teams .filter-row-item ');
	if (list_filter.length > 0) {
		jQuery('div#filters-row-close ').addClass('d-block').removeClass('d-none');
	} else {
		jQuery('div#filters-row-close ').addClass('d-block').addClass('d-none');
		jQuery('div#tv-filters .dropdown-list-teams .show-all .dropdown-filter-checkbox').addClass('checked-true');
		jQuery('div#tv-filters .dropdown-list-teams .show-all .dropdown-filter-checkbox').removeClass('checked-false');
		jQuery('div#tv-filters .dropdown-list-teams .show-all .dropdown-filter-checkbox').html('<i class="fas fa-check"></i>');
	}
}
jQuery(
	function ($) {
		var filter_values = [];
		var filter_location = [];
		$('body').on(
			'click',
			'div#filters-row-teams .filters-row-items-teams',
			function () {
				var data_remove = $(this).attr('data-filter');
				$('div#tv-filters .dropdown-list-teams .dropdown-filter-item-teams').each(
					function (e) {
						if ($(this).attr('data-filter') === data_remove) {
							$(this).children('.checked-true')
								.removeClass('checked-true')
								.addClass('checked-false')
								.empty()
						}
					}
				);
				$(this).remove();
				$('#filter-title .dropdown-list-teams .dropdown-filter-item-teams').each(
					function (e) {
						var data_filter = $(this).attr('data-filter');
						if ($(this).children().hasClass('checked-true')) {
							filter_values.push(data_filter);
						} else {
							var index = filter_values.indexOf(data_filter);
							if (index !== -1) {
								filter_values.splice(index, 1);
							}
						}
						if ($(this).hasClass('show-all')) {
							filter_values = [];
						}
					}
				);
				$('#filter-location .dropdown-list-teams .dropdown-filter-item-teams').each(
					function (e) {
						var data_filter = $(this).attr('data-filter');
						if ($(this).children().hasClass('checked-true')) {
							filter_location.push(data_filter);
						} else {
							var index = filter_location.indexOf(data_filter);
							if (index !== -1) {
								filter_location.splice(index, 1);
							}
						}
						if ($(this).hasClass('show-all')) {
							filter_location = [];
						}
					}
				);
				$('.tv-accordion-group').removeClass('active');
				filter_people(filter_values, filter_location);
			}

		);
		function add_remove_close_filter() {
			if ($('#filters-row-items-teams').length === 0) {
				return;
			}

			if ($('#filters-row-items-teams').html().length === 1) {
				$('#filters-row-close')
					.addClass('d-none')
					.removeClass('d-block');
			} else {
				$('#filters-row-close')
					.removeClass('d-none')
					.addClass('d-block');
			}
		}
		$('body').on(
			'click',
			'div#filter-row-close-button',
			function () {
				filter_values = [];
				filter_location = [];
				$(this).parent().removeClass('d-block');
				$(this).parent().addClass('d-none');
				filter_people(filter_values, filter_location);
				$('div#filters-row-teams .filter-row-item').empty();
				$('div#tv-filters .dropdown-list-teams .dropdown-filter-item-teams').each(
					function (e) {
						$(this).children('.checked-true')
							.removeClass('checked-true')
							.addClass('checked-false')
							.empty();
					}
				)
				$('div#tv-filters .dropdown-list-teams .show-all .dropdown-filter-checkbox').addClass('checked-true');
				$('div#tv-filters .dropdown-list-teamst .show-all .dropdown-filter-checkbox').removeClass('checked-false');
				$('div#tv-filters .dropdown-list-teams .show-all .dropdown-filter-checkbox').html('<i class="fas fa-check"></i>');
			}
		);
		$('body').on(
			'click',
			'#filters-row-teams .filter-row-item',
			function () {
				var remove_filter = $(this).attr('data-filter');
				$('div#tv-filters .dropdown-list-teams .dropdown-filter-item-teams').each(
					function (e) {
						if ($(this).attr('data-filter') === remove_filter) {
							$(this).children('.checked-true')
								.removeClass('checked-true')
								.addClass('checked-false')
								.empty();
						}
					}
				);
				$(this).remove();
				$('#filter-title .dropdown-list-teams .dropdown-filter-item-teams').each(
					function (e) {
						var data_filter = $(this).attr('data-filter');
						if ($(this).children().hasClass('checked-true')) {
							filter_values.push(data_filter);
						} else {
							var index = filter_values.indexOf(data_filter);
							if (index !== -1) {
								filter_values.splice(index, 1);
							}
						}
						if ($(this).hasClass('show-all')) {
							filter_values = [];
						}
					}
				);

				$('#filter-location .dropdown-list-teams  .dropdown-filter-item-teams').each(
					function (e) {
						var data_filter = $(this).attr('data-filter');
						if ($(this).children().hasClass('checked-true')) {
							filter_location.push(data_filter);
						} else {
							var index = filter_location.indexOf(data_filter);
							if (index !== -1) {
								filter_location.splice(index, 1);
							}
						}
						if ($(this).hasClass('show-all')) {
							filter_location = [];
						}
					}
				);
				$('.tv-accordion-group').removeClass('active');
				filter_people(filter_values, filter_location);
				close_filters_all();
			}
		)
		let searchParams = window.location.href;
		let param = searchParams.split("?team=");
		if( param[1] ){
  			const teamp = param[1].split("/", 2);
			var nparam;
			if (teamp[1]){
				nparam = teamp[1];
			}else{
				nparam = teamp[0];
			}
		}
		var width = $(window).width();
		if (width > 1024){
			setTimeout(
				function () {
					$('.elementor-widget-teams_view .tv-accordion-group').each(
						function (e) {
							let idgroup = $(this).attr('id');
							if (nparam == idgroup) {
								$(this).addClass('active');
								$('html, body').animate(
									{
										scrollTop: $(this).offset().top - 180
									},
									1000
								);
								return false;
							}
						}
					);
				},
				3000
			);
		}
		if (width < 1024){
			setTimeout(
				function () {
					$('.elementor-widget-teams_view_response .tv-accordion-group').each(
						function (e) {
							let idgroup = $(this).attr('id');
							if (nparam == idgroup) {
								$(this).addClass('active');
								$('html, body').animate(
									{
										scrollTop: $(this).offset().top - 180
									},
									1000
								);
								return false;
							}
						}
					);
				},
				3000
			);
		}
	}
);
